import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import isNil from 'utils/isNil';
import Button from 'components/Core/Button';
import SEO from 'components/Core/SEO';
import WebsiteLayout from '../layouts/websiteLayout';

const ServiceUnavailable = (props) => {
  const pageImages = props.data.allContentfulImage;
  let backImg;
  pageImages.edges.forEach((imageItem) => {
    if (imageItem.node.internalName === '404-wrench') {
      backImg = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    }
  });
  const seo = {
    pageSEO: true,
    robotOptions: { indexable: true },
    pageNode: {
      title: 'https:/www.servicetitan.com/503',
      metaDescription: {
        internal: {
          content: '',
        },
      },
    },
    canonicalUrl: 'https:/www.servicetitan.com/503',
    slug: '503',
    pageCategory: 'Error',
  };
  return (
    <WebsiteLayout>
      <SEO {...seo} />
      <InnerContainer bgImage={backImg}>
        <h2>Oooops...</h2>
        <p>Service unavailable. Don't worry our app still works.</p>
        <Button type="secondary" to="/">
          Go to Homepage
        </Button>
      </InnerContainer>
    </WebsiteLayout>
  );
};

export default ServiceUnavailable;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1152px;
  margin: 0px auto;
  padding: 256px 28px;
  position: relative;
  overflow: hidden;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 256px 50%;

  @media (max-width: 767px) {
    background-position: -290px 230px;
    padding-top: 150px;
    padding-bottom: 300px;
    background-size: 830px;
  }
`;

export const ServiceUnavailableQuery = graphql`
  query ServiceUnavailableQuery {
    allContentfulImage(
      filter: {
        contentful_id: {
          in: [
            "4JPMY7Ky3OHOm8bMZCL0hh" # 404-wrench
          ]
        }
      }
    ) {
      edges {
        node {
          internalName
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
